import {
  h,
  customElement,
  useProp,
  useState,
  useEvent,
  useEffect,
} from "atomico";
import { useMediaResize } from "@atomico/kit/use-media-resize";

let optionEventTab = { bubbles: true };

function Tab() {
  let [, setChecked] = useProp("checked");
  let dispatchConnectTab = useEvent("ConnectTab", optionEventTab);
  let dispatchDisconectTab = useEvent("DisconectTab", optionEventTab);

  useEffect(() => {
    dispatchConnectTab();
    return dispatchDisconectTab;
  }, []);

  return <host onclick={() => setChecked(true)}></host>;
}

function Tabs() {
  let [tabs, setTabs] = useState([]);
  let [state, ref] = useMediaResize("full, small 520px");
  let currentTab = tabs.find((tab) => tab.checked);

  console.log(state);

  return (
    <host
      shadowDom
      onConnectTab={({ target }) => {
        setTabs((tabs) => (tabs.includes(target) ? tabs : [...tabs, target]));
      }}
      onDisconectTab={({ target }) => {
        setTabs((tabs) =>
          tabs.includes(target) ? tabs.filter((tab) => tab != target) : tabs
        );
      }}
      onChangeTab={({ target }) => {
        if (target.checked && currentTab != target) {
          if (currentTab) currentTab.checked = false;
        }
        setTabs((tabs) => [...tabs]);
      }}
    >
      {state == "small" ? (
        <select
          onchange={({ target }) => {
            setTabs((tabs) =>
              tabs.map((tab) => {
                tab.checked = tab.for == target.value ? 1 : 0;
                return tab;
              })
            );
          }}
          style="width:100%;height:40px;background:rgba(255,255,255,.15);color:white;border:1px solid white;border-radius:5px"
        >
          {tabs &&
            tabs.map((target) => (
              <option
                style="color:black"
                value={target.for}
                selected={currentTab == target}
              >
                {target.for}
              </option>
            ))}
        </select>
      ) : (
        <slot name="header"></slot>
      )}
      <div ref={ref}>
        <slot name={currentTab ? currentTab.for : "unchecked"}></slot>{" "}
      </div>
    </host>
  );
}

Tab.props = {
  for: String,
  checked: {
    type: Boolean,
    reflect: true,
    event: {
      type: "ChangeTab",
      bubbles: true,
    },
  },
};

customElement("ui-tab", Tab);
customElement("ui-tabs", Tabs);
